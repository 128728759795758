<template>
    <div class="addPoints">
        <!-- <modal name="addPointsPage" :width="500" :height="280" draggable="window-header"> -->
        <div class="col-md-6 mid_div">
            <div class="col-md-12 pb-10 text-center">
                <h3 for="remark" class="window-header" style="border-bottom: 1px solid green;">Add More Points</h3>
            </div>
            <div class="col-md-12 row mt-20">
                <div class="col-md-4">
                    <label>Search By</label>
                </div>
                <div class="col-md-8">
                    <select class="form-control" v-model="searchtype" >
                        <option value="1">Customer Name</option>
                        <option value="2">VSDIGI-ID</option>
                    </select>
                </div>
            </div>
            <div class="col-md-12 row mt-20">
                <div class="col-md-4">
                    <label v-if="searchtype==1">Customer name</label>
                    <label v-if="searchtype==2">VSDIGI-ID</label>
                </div>
                <div class="col-md-8">
                    <input type="text" class="form-control" @input="searchCustomer($event)" :value="selectedusername"
                        :placeholder="searchtype == 1 ? 'Enter customer name.' : 'Enter VSDIGI-ID'" />
                    <div class="overlap-div">
                        <div class="autocomplete_200" v-if="searchCustomerResult.length > 0 && this.selecteduser == null">
                            <ul class="list-group">
                                <li class="list-group-item pointer" v-for="customer in searchCustomerResult"
                                    :key="customer.id" @click="selectCustomer(customer)">
                                    {{ customer.name }} <span v-if="customer.generatedid!=null">({{ customer.generatedid }})</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 row mt-20">
                <div class="col-md-4">
                    <label>Date</label>
                </div>
                <div class="col-md-8">
                    <input type="date" class="form-control" v-model="transactiondate" />
                </div>
            </div>
            <div class="col-md-12 row mt-20">
                <div class="col-md-4">
                    <label>Enter Point</label>
                </div>
                <div class="col-md-8">
                    <input type="number" class="form-control" v-model="points" />
                </div>
            </div>
            <div class="col-md-12 flex-between-row pt-20">
                <button class="btn bg-danger text-white btn-sm chkcondition" @click="cancel()">Cancel</button>
                <button class="btn bg-success text-white btn-sm chkcondition" @click="savePoint()">Submit</button>
            </div>
        </div>

        <!-- </modal> -->
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import debounce from 'lodash.debounce'

export default {
    data() {
        return {
            description: '',
            transactiondate: '',
            points: 0,
            selecteduser: null,
            searchCustomerResult: [],
            searchtype: 1,
            selectedusername:''
        }
    },
    computed: {
        ...mapGetters(['selectedstoreid'])
    },
    mounted() {
        console.log("selected store id ", this.selectedstoreid)
    },
    methods: {
        cancel() {
            this.selectedusername = ''
            this.transactiondate = ''
            this.points = 0
            this.searchCustomerResult = []
            this.searchtype = 1
            this.selecteduser = null
            this.$parent.viewno = 0
        },
        savePoint() {
            if (this.selecteduser == null || this.selecteduser?.id == 0) {
                alert('Select Customer.')
            } else if (this.transactiondate == '' && this.transactiondate == null) {
                alert('Please select a date.')
            } else if (parseFloat(this.points) < 1) {
                alert('Please enter valid points.')
            } else {
                let param = {
                    customerid: this.selecteduser.id, transactiondate: this.transactiondate,
                    storeid: this.selectedstoreid, amount: this.points
                }
                console.log(param)
                axios.post('api/manual/points/save', param)
                    .then((response) => {
                        console.log(response.data)
                        if(response.data.status){
                            this.cancel()
                            this.$parent.viewno = 0
                            this.$parent.refresh()
                        }
                    })
                    .catch((error) => console.error(error))
            }
        },
        searchCustomer: debounce(function (event) {
            if (event.target.value != '') {
                let param = {iscustomer: 1, fetchcolumns: 'id,name,generatedid'}
                if (this.searchtype == 1) {
                    param.name = event.target.value
                } else {
                    param.generatedid = event.target.value
                }
                axios.post('api/users/fetch', param)
                    .then((response) => {
                        this.searchCustomerResult = response.data
                        this.selectedusername = event.target.value
                        this.selecteduser = null
                    })
                    .catch((error) => console.error(error))
            }

        }, 2000),
        selectCustomer(item) {
            this.selecteduser = item
            this.selectedusername = this.searchtype == 1 ? item.name : item.generatedid
            console.log("selected user ", this.selecteduser)
        }


    }
}
</script>
<style>
.addPoints {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

.mid_div {
    border: 1px solid gray;
    padding: 20px;
    border-radius: 5px;
    background-color: white;
}
</style>