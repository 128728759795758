<template>
    <div>

        <div v-if="viewno == 0">
            <div class="col-md-12">
                <div class="row" style="margin-top:20px">
                    <div class="col-md-6">
                        <h3 class="page-header">
                            <i class="fa fa fa-user animated bounceInDown show-info"></i>
                            Points Ledger
                        </h3>
                    </div>
                    <div class="col-md-6 text-end">
                        <button class="btn btn-primary" @click="viewno=2"> Add Points</button>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-body" style="background: none;">
                        <table class="table table-bordered table-hover table-striped display" id="tblmanual">
                            <thead class="table-font" style="background: #475c78;color: #fff;">
                                <th class="visible-lg">#</th>
                                <th>VSDIGI-ID</th>
                                <th>Customer Name</th>
                                <th>Transaction Date</th>
                                <th>Balance</th>
                                <th style="width: 20%;">Detail</th>
                            </thead>
                            <tbody class="table-font">
                                <tr v-for="(item, index) in allpoints" :key="item.id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.customer?.generatedid ?? 'N/A' }}</td>
                                    <td>{{ item.customer?.name ?? 'N/A' }}</td>
                                    <td>{{ item.transactiondate ?? 'N/A' }}</td>
                                    <td>{{ item.balance }}</td>
                                    <td style="width: 20%;">
                                        <button class="btn btn-primary" @click="detail(item.customerid)"> Detail</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <points-detail v-if="viewno == 1" :customerid="this.customerid"></points-detail>
        <add-points v-if="viewno == 2" ></add-points>
    </div>

</template>
<script>
import PointsDetail from "./PointsDetail.vue";
import AddPoints from "./AddPoints.vue";
import axios from "axios";
export default {
    components: { PointsDetail,AddPoints },

    data() {
        return {
            currentpage: 1,
            noofrec: 10,
            allpoints: [],
            customerid: 0,
            viewno: 0
        }
    },
    computed: {

    },

    mounted() {
        this.refresh();
    },
    methods: {
        clickCallback(currentpage) {
            this.currentpage = currentpage
            this.$store.commit("assigncurrentpage", currentpage);
        },
        refresh() {
            axios.get('api/points/fetchall')
                .then(response => {
                    this.allpoints = response.data.ledger;
                })
                .catch(error => console.log(error))
        },
        detail(customerid) {
            this.viewno = 1
            this.customerid = customerid
            // this.$router.push({ path: '/customer/detail/' + customerid });
        }

    }
}
</script>

<style scoped>
.btn-primary:hover {
    text-decoration: none !important;
    background-color: #3a476b !important;
}
</style>